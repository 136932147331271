// App.js

import React, { useRef, useState } from 'react'
import { View, Text } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Calculator from './src/screens/CalculatorScreen'
import Shopping from './src/screens/ShoppingScreen'
import Instructions from './src/screens/Instructions'
import NavMain from './src/components/NavMain'
import { Colors } from './src/styles/base'
import styles from './src/styles/components/NavMain'
const Stack = createStackNavigator()

function App() {
  const navigationRef = useRef(null)
  const [isCalculatorReady, setCalculatorReady] = useState(false)
  const [currentRouteName, setCurrentRouteName] = React.useState('Calculator')
  const [calculatorData, setCalculatorData] = useState({
    sacks: '',
    people: '',
    lbs: '',
    pricePerlb: '',
  })

  function setCalculatorReadyState(isReady) {
    setCalculatorReady(isReady)
  }

  function updateCalculatorData(data) {
    setCalculatorData(data)
  }

  // Function to handle navigation state changes
  const onNavigationStateChange = (state) => {
    const route = getActiveRouteName(state)
    setCurrentRouteName(route)
  }

  // Helper function to get the active route name
  const getActiveRouteName = (state) => {
    const route = state.routes[state.index]
    if (route.state) {
      // Dive into nested navigators
      return getActiveRouteName(route.state)
    }
    return route.name
  }

  const [checkboxStates, setCheckboxStates] = useState({})

  const updateCheckboxStates = (newState) => {
    setCheckboxStates(newState)
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer
        ref={navigationRef}
        onStateChange={onNavigationStateChange}>
        <View style={styles.stackNavigator}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Calculator" options={{ title: 'Crawculator' }}>
              {(props) => (
                <Calculator
                  {...props}
                  setCalculatorReadyState={setCalculatorReadyState}
                  updateCalculatorData={updateCalculatorData}
                />
              )}
            </Stack.Screen>

            <Stack.Screen name="Shopping">
              {(props) => (
                <Shopping
                  {...props}
                  checkboxStates={checkboxStates}
                  updateCheckboxStates={updateCheckboxStates}
                />
              )}
            </Stack.Screen>
            <Stack.Screen
              name="Instructions"
              component={Instructions}
              options={{ title: 'Instructions' }}
            />
          </Stack.Navigator>
          <NavMain
            navigationRef={navigationRef}
            isCalculatorReady={isCalculatorReady}
            calculatorData={calculatorData}
            currentRouteName={currentRouteName}
          />
          <Text
            style={{
              color: 'white',
              fontSize: 10,
              textAlign: 'center',
              paddingBottom: 20,
              paddingTop: 0,
            }}>
            Coming soon to the app store.
          </Text>
        </View>
      </NavigationContainer>
    </SafeAreaProvider>
  )
}

export default App
