export const primaryColor = '#FF412E'
export const secondaryColor = '#001F3F'
export const tertiaryColor = '#0074D9'
export const blue = '#0074D9'
export const babyblue = '#7FDBFF'
export const white = '#FFFFFF'
export const gray = '#666666'
export const black = '#000000'
export const lime = '#01FF70'
export const orange = '#FF851B'
export const red = '#FF4136'
export const green = '#2ECC40'
export const yellow = '#FFDC00'

export const white90 = 'rgba(255, 255, 255, 0.9)'
export const white80 = 'rgba(255, 255, 255, 0.8)'
export const white70 = 'rgba(255, 255, 255, 0.7)'
export const white60 = 'rgba(255, 255, 255, 0.6)'
export const white50 = 'rgba(255, 255, 255, 0.5)'
export const white40 = 'rgba(255, 255, 255, 0.4)'
export const white30 = 'rgba(255, 255, 255, 0.3)'
export const white20 = 'rgba(255, 255, 255, 0.2)'
export const white10 = 'rgba(255, 255, 255, 0.1)'
export const black90 = 'rgba(0, 0, 0, 0.9)'
export const black80 = 'rgba(0, 0, 0, 0.8)'
export const black70 = 'rgba(0, 0, 0, 0.7)'
export const black60 = 'rgba(0, 0, 0, 0.6)'
export const black50 = 'rgba(0, 0, 0, 0.5)'
export const black40 = 'rgba(0, 0, 0, 0.4)'
export const black30 = 'rgba(0, 0, 0, 0.3)'
export const black20 = 'rgba(0, 0, 0, 0.2)'
export const black10 = 'rgba(0, 0, 0, 0.1)'

// Statuses
export const disabledColor = '#777777'
export const errorColor = red
export const successColor = lime
export const warningColor = orange
