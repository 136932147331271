// NavMain.jsx

import React, { Component } from 'react'
import { View } from 'react-native'
import IconLink from './IconLink'
import NavMainStyles from '../styles/components/NavMain'
import { Colors } from '../styles/base'
class NavMain extends Component {
  navigate = (routeName, params) => {
    this.props.navigationRef.current?.navigate(routeName, params)
  }

  render() {
    const activeColor = Colors.primaryColor
    const inactiveColor = 'white'

    return (
      <View>
        <View style={NavMainStyles.container}>
          <IconLink
            icon_name="calculator-outline"
            icon_color={
              this.props.currentRouteName === 'Calculator'
                ? activeColor
                : inactiveColor
            }
            icon_label="Crawculator"
            on_press={() => this.navigate('Calculator')}
            label_color={
              this.props.currentRouteName === 'Calculator'
                ? activeColor
                : inactiveColor
            }
          />
          <IconLink
            icon_name="cart-outline"
            icon_color={
              this.props.currentRouteName === 'Shopping'
                ? activeColor
                : inactiveColor
            }
            icon_label="Shopping List"
            on_press={() =>
              this.navigate('Shopping', this.props.calculatorData)
            }
            label_color={
              this.props.currentRouteName === 'Shopping'
                ? activeColor
                : inactiveColor
            }
          />
          <IconLink
            icon_name="flame-outline"
            icon_color={
              this.props.currentRouteName === 'Instructions'
                ? activeColor
                : inactiveColor
            }
            icon_label="Boil Instructions"
            label_color={
              this.props.currentRouteName === 'Instructions'
                ? activeColor
                : inactiveColor
            }
            on_press={() => this.navigate('Instructions')}
          />
        </View>
      </View>
    )
  }
}

export default NavMain
