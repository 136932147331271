import { StyleSheet } from 'react-native'
import { Colors, Spacing } from '../base/'

const InstructionsStyles = {
  scrollContainer: {},
  container: {
    backgroundColor: Colors.secondaryColor,
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
  },
  row: {
    flexDirection: 'row',
  },

  // Text
  header: {
    color: Colors.white,
    fontSize: 32,
    paddingBottom: Spacing.globalPadding,
  },
  body: {
    color: Colors.white,
    fontSize: 18,
    padding: Spacing.globalPadding,
    lineHeight: 30,
  },

  // TODO: Make this a global container style
  content: {
    paddingHorizontal: Spacing.globalPadding,
    paddingVertical: Spacing.globalPaddingLarge,
    width: '100%',
  },
  listItem: {
    borderBottomWidth: 1,
    paddingVertical: 20,
    width: '100%',
    color: 'white',
    fontSize: 16,
  },
  listItemText: {
    color: 'white',
    width: '100%',
    fontSize: 20,
    marginBottom: Spacing.globalMarginMedium,
  },
}

export default StyleSheet.create(InstructionsStyles)
