import { StyleSheet } from 'react-native'

const HeaderStyles = {
  h1: {
    color: 'white',
    fontSize: 24,
    marginVertical: 10,
    width: '100%',
    textAlign: 'center',
  },
  h2: {
    color: 'white',
    fontSize: 17,
    marginVertical: 20,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },
}

export default StyleSheet.create(HeaderStyles)
