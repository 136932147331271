// ShoppingList.jsx

import React, { useState, Component } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styles from '../styles/components/ShoppingListStyles'
import { Colors } from '../styles/base'
import { getQuantity, getItemCost } from '../helpers/recipes'
import { getRecipes, getBatches } from '../constants/recipe'
import { MAX_BATCHES } from '../constants/globals'
import Ionicons from '@expo/vector-icons/Ionicons'

export const maxBatches = MAX_BATCHES

function getMeasurementText(ingredient) {
  return ingredient ? ` - ${ingredient}` : ' - '
}

export default class ShoppingList extends Component {
  constructor(props) {
    super(props)
    const { sacks, lbs, pricePerlb, people } = this.props
    this.state = {
      items: this.initializeItems(sacks, lbs, pricePerlb, people), // Initialize with checkbox states
    }
  }

  initializeItems = () => {
    const { sacks, lbs, pricePerlb } = this.props
    const RECIPES = getRecipes({
      lbs,
      crawfishPricePerLbs: pricePerlb,
    })
    // Assume RECIPES[0].ingredients exists and is an array
    return RECIPES[0].ingredients.map((ingredient, i) => ({
      id: i,
      text: `${getQuantity(
        ingredient.quantity,
        ingredient.subsequentBatchQuantityRatio,
        sacks,
        maxBatches,
        ingredient.name,
        lbs,
        ingredient.buyOnce
      )}${getMeasurementText(ingredient.measurement)} ${
        ingredient.name
      }: $ ${getItemCost(
        ingredient.price,
        ingredient.quantity,
        ingredient.subsequentBatchQuantityRatio,
        sacks,
        maxBatches,
        ingredient.name,
        lbs
      )}`,
      checked: false,
    }))
  }

  componentDidMount() {
    // Check if checkboxStates for this shopping list have been passed and use them to initialize or update your list items accordingly.
    if (
      this.props.checkboxStates &&
      Object.keys(this.props.checkboxStates).length > 0
    ) {
      this.setState({ items: this.props.checkboxStates })
    }
  }

  toggleCheckbox = (id) => {
    this.setState(
      (prevState) => ({
        items: prevState.items.map((item) => {
          if (item.id === id) {
            return { ...item, checked: !item.checked }
          }
          return item
        }),
      }),
      () => this.props.updateCheckboxStates(this.state.items)
    )
  }

  renderItems = () => {
    return this.state.items.map((item) => (
      <View key={item.id} style={styles.itemContainer}>
        <TouchableOpacity
          onPress={() => this.toggleCheckbox(item.id)}
          style={styles.listItemWrapper}>
          <Ionicons
            name={item.checked ? 'checkmark-circle-outline' : 'ellipse-outline'}
            size={32}
            color={'rgba(255, 255, 255, 0.65)'}
            style={styles.checkbox}
          />
          <Text
            style={[
              styles.listItemText,
              item.checked && styles.listItemTextisChecked,
            ]}>
            {item.text}
          </Text>
        </TouchableOpacity>
      </View>
    ))
  }

  render() {
    const { sacks, lbs, pricePerlb, people } = this.props
    const RECIPES = getRecipes({
      lbs,
      crawfishPricePerLbs: pricePerlb,
    })
    const getTotalCost = (ingredients) => {
      let total = 0
      ingredients.forEach((ingredient) => {
        total += getItemCost(
          ingredient.price,
          ingredient.quantity,
          ingredient.subsequentBatchQuantityRatio,
          sacks,
          maxBatches,
          ingredient.name,
          lbs
        )
      })

      const roundedTotal = Math.round(total)
      const formattedTotal = roundedTotal.toLocaleString()
      const getCostPerPerson = Math.round(roundedTotal / people)

      return (
        <View>
          <Text
            style={{
              fontSize: 30,
              marginTop: 36,
              marginBottom: 10,
              fontWeight: 'bold',
              color: Colors.successColor,
              textAlign: 'center',
            }}>
            Est. Total: ${formattedTotal}
          </Text>
          <Text
            style={{
              color: 'white',
              fontSize: 20,
              marginVertical: 10,
              fontWeight: 'normal',
              textAlign: 'center',
            }}>
            ${getCostPerPerson} Per Person
          </Text>
        </View>
      )
    }
    return (
      <View>
        {this.renderItems()}
        {getTotalCost(RECIPES[0].ingredients)}
      </View>
    )
  }
}
