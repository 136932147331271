import { StyleSheet } from 'react-native'
import { Colors } from '../base'

const NavMainMargin = 0
const NavMainStyles = {
  stackNavigator: {
    flex: 1,
    height: '100%',
    backgroundColor: Colors.secondaryColor,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.black20,
    color: 'white',
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'space-around',
    margin: 10,
    borderRadius: 35,
    bottom: NavMainMargin,
    left: NavMainMargin,
    right: NavMainMargin,
    zIndex: 1,
  },
}

export default StyleSheet.create(NavMainStyles)
