import { StyleSheet } from 'react-native'
import { Colors } from '../base/'

const RecipeStyles = {
  // scrollContainer: {
  // },
  container: {
    backgroundColor: Colors.secondaryColor,
    flex: 1,
    paddingBottom: 0,
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
  },
  row: {
    flexDirection: 'row',
  },
  // SHOPPING LIST
  listContainer: {
    paddingHorizontal: 20,
    width: '100%',
  },
  listItem: {
    borderBottomWidth: 1,
    borderColor: Colors.black30,
    marginBottom: 1,
    paddingVertical: 7,
    width: '100%',
    color: 'white',
  },
  listItemText: {
    color: 'white',
    width: '100%',
    fontSize: 21,
  },
  listItemTextMedium: {
    color: 'white',
    width: '100%',
    fontSize: 18,
  },
}

export default StyleSheet.create(RecipeStyles)
