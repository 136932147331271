// constants/recipe.ts
import { AVG_SACK_LBS } from './globals'

export const getBatches = (sacks) => Math.round(sacks)

export const getRecipes = ({ lbs, people, crawfishPricePerLbs }) => [
  {
    id: 1,
    title: 'Traditional Cajun',
    maxBatches: 3, // Batches Before Water Change
    ingredients: [
      {
        name: 'Live Crawfish',
        perSackMeasurement: `${AVG_SACK_LBS}`,
        ingredientMeasurement: 'lbs',
        measurement: 'lbs',
        quantity: lbs,
        subsequentBatchQuantityRatio: 1,
        price: crawfishPricePerLbs,
        timeIn: '00:45:00',
        instructions: 'Washed',
        buyOnce: false,
      },
      {
        name: 'Lemons',
        perSackMeasurement: "10",
        ingredientMeasurement: '',
        measurement: '',
        quantity: 10,
        subsequentBatchQuantityRatio: 0.5,
        price: 0.5,
        timeIn: '00:15:00',
        instructions: 'Cut in half',
        buyOnce: false,
      },
      {
        name: 'Oranges',
        perSackMeasurement: "6",
        ingredientMeasurement: '',
        measurement: '',
        quantity: 6,
        subsequentBatchQuantityRatio: 0.5,
        price: 0.45,
        timeIn: '00:15:00',
        instructions: 'Cut in half',
        buyOnce: false,
      },
      {
        name: 'Sweet Corn',
        quantity: 4,
        perSackMeasurement: 4,
        ingredientMeasurement: "Ears",
        measurement: 'Ears',
        price: 1.25,
        subsequentBatchQuantityRatio: 0.5,
        timeIn: '00:60:00',
        instructions: 'Shucked & halved',
        buyOnce: false,
      },
      {
        name: 'Celery',
        perSackMeasurement: "1",
        ingredientMeasurement: "Bunch",
        measurement: 'Bunch',
        quantity: 1,
        subsequentBatchQuantityRatio: 0,
        price: 3,
        timeIn: '00:01:00',
        instructions: 'Chopped',
        buyOnce: false,
      },
      {
        name: 'Garlic',
        perSackMeasurement: "8",
        ingredientMeasurement: "Bulbs",
        measurement: 'Bulbs',
        quantity: 8,
        subsequentBatchQuantityRatio: 0.5,
        price: 0.5,
        timeIn: '00:10:00',
        instructions: 'Halved sideways',
        buyOnce: false,
      },
      {
        name: 'Yellow Onions',
        perSackMeasurement: "6",
        ingredientMeasurement: '',
        measurement: '',
        quantity: 6,
        subsequentBatchQuantityRatio: 0.5,
        price: 0.6,
        timeIn: '00:01:00',
        instructions: 'Cut in half',
        buyOnce: false,
      },
      {
        name: 'Red Potatoes',
        perSackMeasurement: "1",
        ingredientMeasurement: "2lb bag",
        measurement: '2lb bag',
        quantity: 1,
        subsequentBatchQuantityRatio: 1,
        price: 3,
        timeIn: '00:30:00',
        instructions: 'Whole',
        buyOnce: false,
      },
      {
        name: 'Mushrooms',
        perSackMeasurement: "1",
        ingredientMeasurement: "16oz container",
        measurement: '16oz container',
        quantity: 1,
        subsequentBatchQuantityRatio: 1,
        price: 3,
        timeIn: '00:30:00',
        instructions: 'Whole',
        buyOnce: false,
      },
      {
        name: "Zatarain's Pro Boil",
        quantity: 1,
        perSackMeasurement: "1",
        ingredientMeasurement: "53oz Jar",
        measurement: '53oz Jar',
        price: 8.18,
        subsequentBatchQuantityRatio: 0.5,
        timeIn: '00:30:00',
        instructions: '',
        buyOnce: false,
      },
      {
        name: "Zatarain's Liquid Crab Boil",
        quantity: 1,
        perSackMeasurement: "1",
        ingredientMeasurement: "4oz Bottle",
        measurement: '4oz Bottle',
        price: 2.39,
        subsequentBatchQuantityRatio: 0,
        timeIn: '00:16:00',
        instructions: '',
        buyOnce: false,
      },
      {
        name: 'Cayenne Pepper',
        perSackMeasurement: "1",
        ingredientMeasurement: "16oz",
        measurement: '16oz',
        quantity: 1,
        subsequentBatchQuantityRatio: 0.5,
        price: 7.18,
        timeIn: '00:20:00',
        instructions: 'Adjust to taste',
        buyOnce: false,
      },
      {
        name: 'Sugar',
        perSackMeasurement: "1/4",
        ingredientMeasurement: "Cup",
        measurement: '16oz Can',
        quantity: 1,
        subsequentBatchQuantityRatio: 0,
        price: 1.79,
        timeIn: '00:20:00',
        instructions: '',
        buyOnce: true,
      },
      {
        name: 'Whole Cloves',
        perSackMeasurement: "3",
        ingredientMeasurement: '',
        measurement: '0.62 Oz Container',
        quantity: 1,
        subsequentBatchQuantityRatio: 0,
        price: 5,
        timeIn: '00:05:00',
        instructions: '',
        buyOnce: true,
      },
      {
        name: 'Andouille Sausage',
        perSackMeasurement: "1",
        ingredientMeasurement: "lb",
        measurement: 'lb',
        quantity: 1,
        subsequentBatchQuantityRatio: 1,
        price: 7.5,
        timeIn: '00:30:00',
        instructions: 'Sliced',
        buyOnce: false,
      },
    ],
  },
]


