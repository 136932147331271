import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '../base'
import { rounded } from '../base/corners'

export const activeOpacity = 0.8

const Buttons = {
  base: {
    alignItems: 'center',
    borderColor: 'white',
    borderRadius: rounded,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    textAlign: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  textStyling: {
    color: Colors.white,
    fontFamily: Fonts.fontHeavy,
    fontSize: 36,
    letterSpacing: 4,
  },
  shadowProp: {
    shadowColor: Colors.black,
    shadowOffset: { width: 7, height: 7 },
    shadowOpacity: 0.5,
    shadowRadius: 0,
  },
  primary: {
    backgroundColor: Colors.primaryColor,
    borderColor: 'white',
    color: 'white',
    margin: 6,
    borderRadius: 5,
    padding: 10,
  },
  secondary: {
    backgroundColor: Colors.secondaryColor,
    borderColor: 'white',
    color: 'white',
  },
  primaryText: {
    color: Colors.white,
    fontSize: 18,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  disabledButton: {
    backgroundColor: Colors.disabledColor,
    opacity: 0.3,
  },
  icon: {
    marginLeft: 7,
    marginTop: -4,
  },
}

export default StyleSheet.create(Buttons)
