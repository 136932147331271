import { StyleSheet } from 'react-native'
import { Colors } from '../base/'

const ShoppingListStyles = {
  scrollContainer: {},
  container: {
    backgroundColor: Colors.secondaryColor,
    flex: 1,
    paddingBottom: 0,
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
  },
  row: {
    flexDirection: 'row',
  },
  listItemText: {
    color: 'white',
    width: '100%',
    fontSize: 21,
    paddingRight: 40,
  },
  listItemTextisChecked: {
    textDecorationLine: 'line-through',
    opacity: 0.4,
  },
  listItemTextMedium: {
    color: 'white',
    width: '100%',
    fontSize: 18,
  },
  listItemWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.06)',
  },
  checkbox: {
    color: 'rgba(255, 255, 255, 0.6)',
    height: 30,
    width: 30,
    marginRight: 15,
    marginTop: -1,
  },
}

export default StyleSheet.create(ShoppingListStyles)
