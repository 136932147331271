import { StyleSheet } from 'react-native'
import { Colors, Fonts } from '../base'

const CalculatorStyles = {
  scrollContainer: {
    minHeight: '100%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.secondaryColor,
    marginTop: 0,
    paddingTop: 30,
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  h1: {
    color: Colors.babyblue,
    fontSize: 30,
    textAlign: 'center',
    width: '100%',
    fontFamily: Fonts.fontHeavy,
  },
  h2: {
    color: Colors.primaryColor,
    fontSize: 21,
    marginTop: 5,
    textAlign: 'center',
    width: '100%',
    fontFamily: Fonts.fontHeavy,
  },
  tempText: {
    color: 'white',
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 20,
  },
}

export default StyleSheet.create(CalculatorStyles)
