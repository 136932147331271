import { StyleSheet } from 'react-native'
import { Colors } from '../base'

const FormStyles = {
  textInput: {
    alignItems: 'center',
  },
  input: {
    backgroundColor: 'white',
    borderColor: '#666',
    borderRadius: 2,
    borderWidth: 2,
    height: 50,
    marginBottom: 20,
    marginHorizontal: 10,
    marginVertical: 10,
    padding: 10,
    width: 90,
    fontSize: 21,
    fontWeight: 'bold',
  },
  inputFullWidth: {
    backgroundColor: 'white',
    borderColor: '#666',
    borderRadius: 2,
    borderWidth: 2,
    height: 50,
    marginBottom: 20,
    marginHorizontal: 10,
    marginVertical: 10,
    padding: 10,
    width: '100%',
    fontSize: 21,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  inputLabel: {
    color: 'white',
    fontSize: 18,
    marginBottom: 5,
    marginTop: 25,
  },
  center: {
    alignItems: 'center',
  },
}

export default StyleSheet.create(FormStyles)
