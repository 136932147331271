import { StyleSheet } from 'react-native'
import { Colors } from '../base'

export const iconLinkSize = 120

const iconLinkStyles = {
  container: {
    alignItems: 'center',
    color: 'white',
    height: iconLinkSize,
    justifyContent: 'center',
    width: iconLinkSize,
  },
  icon: {
    color: Colors.white,
    fontSize: 18,
    marginVertical: 4,
  },
  active: {
    backgroundColor: Colors.secondaryColor,
    borderColor: 'white',
    color: 'white',
  },
  text: {
    color: Colors.white,
    fontSize: 12,
  },
  isDisabled: {
    opacity: 0.4,
  },
}

export default StyleSheet.create(iconLinkStyles)
