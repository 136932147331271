// RECIPE HELPERS
import { roundNum } from './utility'
import { Alert } from 'react-native'
import { getBatches } from '../constants/recipe'

export const getQuantity = (
  quantity,
  subsequentBatchQuantityRatio,
  sacks,
  maxBatches,
  name,
  lbs,
  buyOnce
) => {
  const batches = getBatches(sacks)
  // Alert.alert('batches', batches.toString())

  // Change the water after this many batches
  const waterChanges = Math.ceil(batches / maxBatches)

  // Number of batches, after the first batch, since we've changed the water
  const subsequentBatches = batches - waterChanges

  // Amount to put in, of the ingredient on batches with seasoned water
  const subsequentBatchQuantity = quantity * subsequentBatchQuantityRatio

  // How much to buy
  const itemQuantity = buyOnce
    ? 1
    : quantity * waterChanges + subsequentBatchQuantity * subsequentBatches

  if (name == 'Live Crawfish') {
    return Math.round(lbs)
  } else {
    return Math.floor(itemQuantity)
  }
}

export const getItemCost = (
  itemPrice,
  quantity,
  subsequentBatchQuantityRatio,
  sacks,
  maxBatches,
  name,
  lbs
) => {
  return (
    roundNum(
      itemPrice *
        getQuantity(
          quantity,
          subsequentBatchQuantityRatio,
          sacks,
          maxBatches,
          name,
          lbs
        ) *
        100
    ) / 100
  )
}
