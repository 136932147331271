// IconLink.jsx

import React, { Component } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import IconLinkStyles from '../styles/components/IconLink'
import { activeOpacity } from '../styles/components/Buttons'
import { Alert } from 'react-native'

// https://oblador.github.io/react-native-vector-icons/
export default class IconLink extends Component {
  render() {
    const isDisabled = this.props.isDisabled
    return (
      <TouchableOpacity
        activeOpacity={activeOpacity}
        onPress={() => {
          if (!isDisabled) {
            this.props.on_press()
          }
        }}
        style={[
          IconLinkStyles.container,
          isDisabled ? IconLinkStyles.isDisabled : '',
        ]}
        disabled={isDisabled}>
        <Ionicons
          name={this.props.icon_name || 'chevron-forward-outline'}
          size={this.props.icon_size || 30}
          color={this.props.icon_color || 'white'}
        />
        <Text
          style={[
            IconLinkStyles.text,
            { color: this.props.label_color || 'white' },
          ]}>
          {this.props.icon_label}
        </Text>
      </TouchableOpacity>
    )
  }
}
