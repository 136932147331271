// CalculatorScreen.jsx

import 'react-native-gesture-handler'
import React from 'react'
import { Alert, View, Text, TextInput } from 'react-native'
import styles from '../styles/components/CalculatorStyles'
import buttonStyles from '../styles/components/Buttons'
import formStyles from '../styles/components/FormStyles'
import Button from '../components/Button'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { roundNum, stringify } from '../helpers/utility'
import { LBS_PER_PERSON, AVG_SACK_LBS } from '../constants/globals'
import { SafeAreaView } from 'react-native-safe-area-context'

export default class Calculator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      people: '',
      lbs: '',
      sacks: '',
      pricePerlb: '',
      buttonStyles: buttonStyles.disabledButton,
      isCalculatorReady: this.props.isCalculatorReady,
      isButtonDisabled: true,
    }
  }

  setPeople = (people) => {
    this.setState(
      {
        people,
        sacks: roundNum(((people * LBS_PER_PERSON) / AVG_SACK_LBS) * 10) / 10,
        lbs: roundNum(people * LBS_PER_PERSON),
      },
      () => {
        this.props.updateCalculatorData({
          people: this.state.people,
          sacks: this.state.sacks,
          lbs: this.state.lbs,
          pricePerlb: this.state.pricePerlb,
        })
      }
    )
  }

  setLbs = (lbs) => {
    this.setState(
      {
        lbs,
        sacks: roundNum((lbs / AVG_SACK_LBS) * 10) / 10,
        people: Math.ceil(lbs / LBS_PER_PERSON),
      },
      () => {
        this.props.updateCalculatorData({
          people: this.state.people,
          sacks: this.state.sacks,
          lbs: this.state.lbs,
          pricePerlb: this.state.pricePerlb,
        })
      }
    )
  }

  setSacks = (sacks) => {
    this.setState(
      {
        sacks,
        people: roundNum((sacks * AVG_SACK_LBS) / LBS_PER_PERSON),
        lbs: (AVG_SACK_LBS * sacks * 10) / 10,
      },
      () => {
        this.props.updateCalculatorData({
          people: this.state.people,
          sacks: this.state.sacks,
          lbs: this.state.lbs,
          pricePerlb: this.state.pricePerlb,
        })
      }
    )
  }

  setPricePerlb = (pricePerlb) => {
    this.setState(
      {
        pricePerlb: pricePerlb,
      },
      () => {
        this.props.updateCalculatorData({
          people: this.state.people,
          sacks: this.state.sacks,
          lbs: this.state.lbs,
          pricePerlb: this.state.pricePerlb,
        })
      }
    )
  }

  setButtonState = (num) => {
    const isReady = num > 0
    if (isReady) {
      this.setState({
        buttonStyles: buttonStyles.primary,
        isButtonDisabled: false,
      })
    } else {
      this.setState({
        buttonStyles: buttonStyles.disabledButton,
        isButtonDisabled: true,
      })
    }
    // Update isCalculatorReady in the parent component
    this.props.setCalculatorReadyState(isReady)
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <KeyboardAwareScrollView contentContainerStyle={styles.scrollContainer}>
          <View>
            <Text style={styles.h1}>BOIL HERO</Text>
            <Text style={styles.h2}>Crawculator</Text>
            <View style={styles.row}>
              <View style={formStyles.textInput}>
                <Text style={formStyles.inputLabel}>Pounds</Text>
                <TextInput
                  maxLength={4}
                  style={formStyles.input}
                  placeholder=""
                  placeholderTextColor="#444"
                  onChangeText={(lbs) => {
                    ;[this.setLbs(lbs), this.setButtonState(lbs)]
                  }}
                  keyboardType={'numeric'}
                  value={this.state.lbs > 0 ? stringify(this.state.lbs) : ''}
                />
              </View>
              <View style={formStyles.textInput}>
                <Text style={formStyles.inputLabel}>People</Text>
                <TextInput
                  maxLength={3}
                  style={formStyles.input}
                  placeholder=""
                  placeholderTextColor="#444"
                  onChangeText={(people) => {
                    ;[this.setPeople(people), this.setButtonState(people)]
                  }}
                  keyboardType={'numeric'}
                  value={
                    this.state.people > 0 ? stringify(this.state.people) : ''
                  }
                />
              </View>
              <View style={formStyles.textInput}>
                <Text style={formStyles.inputLabel}>Sacks</Text>
                <TextInput
                  maxLength={3}
                  style={formStyles.input}
                  placeholder=""
                  placeholderTextColor="#444"
                  onChangeText={(sacks) => {
                    ;[this.setSacks(sacks), this.setButtonState(sacks)]
                  }}
                  keyboardType={'decimal-pad'}
                  value={
                    this.state.sacks > 0 ? stringify(this.state.sacks) : ''
                  }
                />
              </View>
            </View>
            <View style={formStyles.center}>
              <Text style={formStyles.inputLabel}>$ Price per/lb</Text>
              <TextInput
                style={formStyles.input}
                placeholder=""
                placeholderTextColor="#444"
                onChangeText={(pricePerlb) => {
                  this.setPricePerlb(pricePerlb)
                }}
                keyboardType={'decimal-pad'}
                value={
                  this.state.pricePerlb > 0
                    ? stringify(this.state.pricePerlb)
                    : ''
                }
              />
            </View>
            <View>
              <Button
                buttonText="NEXT"
                styles={this.state.buttonStyles}
                disabled={this.state.isButtonDisabled}
                icon_name="arrow-right"
                onPress={() =>
                  this.props.navigation.navigate('Shopping', {
                    sacks: this.state.sacks,
                    people: this.state.people,
                    lbs: this.state.lbs,
                    pricePerlb: this.state.pricePerlb,
                  })
                }
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    )
  }
}
