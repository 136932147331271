import React, {Component} from 'react'
import {Text, TouchableOpacity} from 'react-native'
import Feather from '@expo/vector-icons/Feather';
import buttonStyles from '../styles/components/Buttons'

export default class Button extends Component {
  render() {
    return (
      <TouchableOpacity
        activeOpacity={buttonStyles.activeOpacity} 
        disabled={this.props.disabled}
        style={[
          buttonStyles.base,
          buttonStyles.primary,
          buttonStyles.shadowProp,
          this.props.styles
        ]}
        onPress={this.props.onPress}>
        <Text style={buttonStyles.textStyling}>
          {this.props.buttonText}
        </Text>
        <Text style={buttonStyles.icon}>
          {this.props.icon_name ? <Feather name={this.props.icon_name} size={this.props.icon_size || 36} color={this.props.icon_color || "white"} /> : ''}
        </Text>
      </TouchableOpacity>
    )
  }
}