import 'react-native-gesture-handler'
import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import Buttons from '../styles/components/Buttons'
import Header from '../components/Header'
import styles from '../styles/components/InstructionsStyles'
import { getRecipes } from '../constants/recipe'
export default class Instructions extends React.Component {
  render() {
    const params = this.props.route.params || {}
    const { lbs, crawfishPricePerLbs } = params

    const recipes = getRecipes({ lbs, crawfishPricePerLbs })

    // Function to loop through ingredients and print name and measurement
    const printIngredientsDetails = (recipes) => {
      let detailsText = ''

      recipes.forEach((recipe, index) => {
        recipe.ingredients.forEach((ingredient) => {
          const instructionsText = ingredient.instructions
            ? ` (${ingredient.instructions})`
            : ''
          const ingredientMeasurementText = ingredient.ingredientMeasurement
            ? ` ${ingredient.ingredientMeasurement}`
            : ''

          detailsText += `${ingredient.perSackMeasurement} -${ingredientMeasurementText} ${ingredient.name} ${instructionsText} \n`
        })
        detailsText += '\n' // Adds an extra newline for spacing between recipes
      })

      return detailsText
    }

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <Header headerText={'Boiling Instructions'} />

          <Text style={styles.body}>
            This is how we boil crawfish. It's is a base recipe that should get
            you started. We hope you'll take what we've presented and make it
            your own.
          </Text>

          <View style={styles.content}>
            <Text style={styles.header}>The Basics</Text>
            <Text style={styles.listItemText}>1. Make a Stock </Text>
            <Text style={styles.listItemText}>2. Cook Vegetables</Text>
            <Text style={styles.listItemText}>3. Add Seasoning</Text>
            <Text style={styles.listItemText}>4. Add Crawfish</Text>
            <Text style={styles.listItemText}>5. Cool Down</Text>
            <Text style={styles.listItemText}>6. Soak</Text>
          </View>

          <View style={styles.content}>
            <Text style={styles.header}>Ingredients</Text>
            <Text style={styles.listItemText}>
              This is the basic ingredients for 1 sack of crawfish, assuming
              you're using a 60 or 80qt pot.
            </Text>
            <Text style={styles.listItem}>
              {printIngredientsDetails(recipes)}
            </Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.header}>1. Make a Stock</Text>
            <Text style={styles.listItemText}>
              • Fill an 80qt pot (preferably with a boil basket) with water 1/3
              of the way
            </Text>
            <Text style={styles.listItemText}>• Bring the water to a boil</Text>
            <Text style={styles.listItemText}>
              • Drop in the Onions, Garlic, Celery, Orange, Lemons Boil for ~
              20-30 mins to develop a vegetable stock
            </Text>
            <Text style={styles.listItemText}>• Add dry seasoning</Text>
            <Text style={styles.listItemText}>
              • Add liquid seasoning, Cayenne, Sugar, Orange Juice
            </Text>
            <Text style={styles.listItemText}>• Stir</Text>
          </View>

          <View style={styles.content}>
            <Text style={styles.header}>3. Cook Vegetables</Text>
            <Text style={styles.listItemText}>
              • Add potatoes, sausage, and mushrooms
            </Text>
            <Text style={styles.listItemText}>• Boil for 10 min</Text>
          </View>

          <View style={styles.content}>
            <Text style={styles.header}>4. Add Crawfish</Text>
            <Text style={styles.listItemText}>
              • Bring the water to a roiling boil.
            </Text>
            <Text style={styles.listItemText}>
              • Drop the live crawfish into the pot and boil for Apx 10 min with
              the lid on.
            </Text>
            <Text style={styles.listItemText}>
              • Stir occasionaly to ensure all crawfish are cooked
            </Text>
          </View>

          <View style={styles.content}>
            <Text style={styles.header}>5. Cool Down</Text>
            <Text style={styles.listItemText}>• Turn the fire off</Text>
            <Text style={styles.listItemText}>• Remove the lid</Text>
            <Text style={styles.listItemText}>• Add the corn</Text>
            <Text style={styles.listItemText}>
              • Cool down the pot by running cool water on the outside of the
              pot with a water hose. Some people prefer 5-10 lbs of ice directly
              in the boil, but there is some debate around this method of
              cooling down the pot as it adds water.
            </Text>
          </View>

          <View style={styles.content}>
            <Text style={styles.header}>6. Soak</Text>
            <Text style={styles.listItemText}>
              • Let the boil soak for 30-45 min
            </Text>
            <Text style={styles.listItemText}>
              • Slowly remove the boil basket from the water
            </Text>
            <Text style={styles.listItemText}>• Drain for 5 min</Text>
            <Text style={styles.listItemText}>
              • Pour on a communal table, and enjoy with friends and family!
            </Text>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}
