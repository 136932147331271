import React, { Component } from 'react'
import { Text, View } from 'react-native'
import styles from '../styles/components/HeaderStyles'

export default class Header extends Component {
  render() {
    return (
      <View>
        <Text style={styles.h2}>{this.props.headerText}</Text>
      </View>
    )
  }
}
