export const bodyFont     = 'Avenir'
export const fontBold     = 'Avenir-Bold'
export const fontDemiBold = 'Avenir-DemiBold'
export const fontHeavy    = 'AvenirNext-Heavy'

{/* <Text style={{fontFamily: 'Avenir'}}>Avenir </Text>
<Text style={{fontFamily: 'Avenir Next'}}>Avenir Next </Text>
<Text style={{fontFamily: 'Avenir Next Condensed'}}>Avenir Next Condensed </Text>
<Text style={{fontFamily: 'Avenir-Black'}}>Avenir-Black </Text>
<Text style={{fontFamily: 'Avenir-BlackOblique'}}>Avenir-BlackOblique </Text>
<Text style={{fontFamily: 'Avenir-Book'}}>Avenir-Book </Text>
<Text style={{fontFamily: 'Avenir-BookOblique'}}>Avenir-BookOblique </Text>
<Text style={{fontFamily: 'Avenir-Heavy'}}>Avenir-Heavy </Text>
<Text style={{fontFamily: 'Avenir-HeavyOblique'}}>Avenir-HeavyOblique </Text>
<Text style={{fontFamily: 'Avenir-Light'}}>Avenir-Light </Text>
<Text style={{fontFamily: 'Avenir-LightOblique'}}>Avenir-LightOblique </Text>
<Text style={{fontFamily: 'Avenir-Medium'}}>Avenir-Medium </Text>
<Text style={{fontFamily: 'Avenir-MediumOblique'}}>Avenir-MediumOblique </Text>
<Text style={{fontFamily: 'Avenir-Oblique'}}>Avenir-Oblique </Text>
<Text style={{fontFamily: 'Avenir-Roman'}}>Avenir-Roman </Text>
<Text style={{fontFamily: 'AvenirNext-Bold'}}>AvenirNext-Bold </Text>
<Text style={{fontFamily: 'AvenirNext-BoldItalic'}}>AvenirNext-BoldItalic </Text>
<Text style={{fontFamily: 'AvenirNext-DemiBold'}}>AvenirNext-DemiBold </Text>
<Text style={{fontFamily: 'AvenirNext-DemiBoldItalic'}}>AvenirNext-DemiBoldItalic </Text>
<Text style={{fontFamily: 'AvenirNext-Heavy'}}>AvenirNext-Heavy </Text>
<Text style={{fontFamily: 'AvenirNext-HeavyItalic'}}>AvenirNext-HeavyItalic </Text>
<Text style={{fontFamily: 'AvenirNext-Italic'}}>AvenirNext-Italic </Text>
<Text style={{fontFamily: 'AvenirNext-Medium'}}>AvenirNext-Medium </Text>
<Text style={{fontFamily: 'AvenirNext-MediumItalic'}}>AvenirNext-MediumItalic </Text>
<Text style={{fontFamily: 'AvenirNext-Regular'}}>AvenirNext-Regular </Text>
<Text style={{fontFamily: 'AvenirNext-UltraLight'}}>AvenirNext-UltraLight </Text>
<Text style={{fontFamily: 'AvenirNext-UltraLightItalic'}}>AvenirNext-UltraLightItalic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-Bold'}}>AvenirNextCondensed-Bold </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-BoldItalic'}}>AvenirNextCondensed-BoldItalic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-DemiBold'}}>AvenirNextCondensed-DemiBold </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-DemiBoldItalic'}}>AvenirNextCondensed-DemiBoldItalic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-Heavy'}}>AvenirNextCondensed-Heavy </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-HeavyItalic'}}>AvenirNextCondensed-HeavyItalic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-Italic'}}>AvenirNextCondensed-Italic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-Medium'}}>AvenirNextCondensed-Medium </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-MediumItalic'}}>AvenirNextCondensed-MediumItalic </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-Regular'}}>AvenirNextCondensed-Regular </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-UltraLight'}}>AvenirNextCondensed-UltraLight </Text>
<Text style={{fontFamily: 'AvenirNextCondensed-UltraLightItalic'}}>AvenirNextCondensed-UltraLightItalic </Text> */}