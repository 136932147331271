import 'react-native-gesture-handler'
import React from 'react'
import { View, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import ShoppingList from '../components/ShoppingList'
import Header from '../components/Header'
import styles from '../styles/components/RecipeStyles'

export default class Shopping extends React.Component {
  render() {
    const { checkboxStates, updateCheckboxStates } = this.props

    const params = this.props.route.params || {}
    const { sacks, people, lbs, pricePerlb } = params
    const headerText =
      `Shopping List:\n` + `${lbs} pounds, at $ ${pricePerlb} /lb`

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <View style={styles.listContainer}>
            <Header headerText={headerText} />
            <ShoppingList
              sacks={sacks}
              lbs={lbs}
              pricePerlb={pricePerlb}
              people={people}
              checkboxStates={checkboxStates}
              updateCheckboxStates={updateCheckboxStates}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}
